export default {
    init() {
        /* eslint-disable */
        // JavaScript to be fired on the home page
        jQuery(document).ready(function() {
            jQuery('.faq__right').theiaStickySidebar({
                // Settings
                additionalMarginTop: 40
            });
        });

        $('.faq__sidebar__list__item a[href*="#"]:not([href="#"])').on('click',function(e) {
            e.preventDefault();
            let offset = 0;
            let target = this.hash;
            if ($(this).data('offset') != undefined) offset = $(this).data('offset');
            $('html, body').stop().animate({
                'scrollTop': $(target).offset().top - offset
            }, 800, 'swing', function() {
                // window.location.hash = target;
            });
        });
        /* eslint-enable */
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
