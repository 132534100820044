export default {
    init() {
        /* eslint-disable */
        // JavaScript to be fired on the home page


        jQuery(document).ready(function($) {



            // Default settings
            let controller = new ScrollMagic.Controller({addIndicators: false});

            let frame0 = $('.rolstoelframes__item0');
            let frame0Animation = frameAnimation(frame0);
            let frame0Scene = frameScene(frame0, frame0Animation, controller)

            let frame1 = $('.rolstoelframes__item1');
            let frame1Animation = frameAnimation(frame1);
            let frame1Scene = frameScene(frame1, frame1Animation, controller)

            let frame2 = $('.rolstoelframes__item2');
            let frame2Animation = frameAnimation(frame2);
            let frame2Scene = frameScene(frame2, frame2Animation, controller)

            let frame3 = $('.rolstoelframes__item3');
            let frame3Animation = frameAnimation(frame3);
            let frame3Scene = frameScene(frame3, frame3Animation, controller)


            function frameAnimation(target) {
                let animation = gsap.fromTo(target.selector, {
                    x: 230,
                    opacity: 0,
                }, {
                    x: 0,
                    opacity: 1,
                    duration: 1
                });

                return animation;
            }

            function frameScene(target, animation, controller) {
                let scene = new ScrollMagic.Scene({
                    triggerElement: target.selector,
                    duration: '40%',
                    triggerHook: 0.9,
                })
                .setTween(animation)
                // .addIndicators(true)
                .addTo(controller)

                return scene;
            }





        });
        /* eslint-enable */
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
