import {
    scrollAnimation,
} from './animationFunctions'

export default {
    init() {
        // JavaScript to be fired on all pages

        $('a[href*="#home"]').on('click', function () {
            $('body').removeClass('front-page-data-external');
        });

        $('a.scroll__to[href*="#"]:not([href="#"])').on('click',function (e) {
            e.preventDefault();
            let offset = 100;
            let target = this.hash;
            if ($(this).data('offset') != undefined) {
                offset = $(this).data('offset');
            }
            $('html, body').stop().animate({
                'scrollTop': $(target).offset().top - offset,
            }, 800, 'swing', function () {
                // window.location.hash = target;
            });
        });


        let dev = false;

    if (dev === true) {
        $('.section').css({
            'border-right' : '1px solid blue',
            })

        $('.main').after('<div style=\'position: fixed; height: 100%; left: 50%; top: 0; width: 1px; background-color: red; z-index: 999;\'></div>')

        // setIntervalfunction to check scroll top postion for faking scrollbar.

        setInterval(doStuff, 1000);
    }

    function doStuff()
    {
        console.log($(window).scrollTop());
    }

        /* eslint-disable */

        // Detect IE version
        var iev=0;
        var ieold = (/MSIE (\d+\.\d+);/.test(navigator.userAgent));
        var trident = !!navigator.userAgent.match(/Trident\/7.0/);
        var rv=navigator.userAgent.indexOf("rv:11.0");

    if (ieold) {
        iev=new Number(RegExp.$1);
    }
    if (navigator.appVersion.indexOf("MSIE 10") != -1) {
        iev=10;
    }
    if (trident&&rv!=-1) {
        iev=11;
    }

        // Firefox or IE 11
    if (typeof InstallTrigger !== 'undefined' || iev == 11) {
        let lastScrollTop = 0;
        $(window).on('scroll', function () {
            let st = $(this).scrollTop();
            if (st < lastScrollTop) {
                $('body').removeClass('goto__scroll');
            } else if (st > lastScrollTop) {
                $('body').addClass('goto__scroll');
            }
            lastScrollTop = st;
        });
    }
        // Other browsers
    else {
        $('body').on('mousewheel', function (e) {
            if (e.originalEvent.wheelDelta > 0) {
                $('body').removeClass('goto__scroll');
            } else if (e.originalEvent.wheelDelta < 0) {
                $('body').addClass('goto__scroll');
            }
        });
    }
        // Parse any JSON previously stored in allEntries
        let viewedPages = JSON.parse(sessionStorage.getItem("allPages"));
    if (viewedPages == null) {
        viewedPages = [];
    }
        let page_id = globals.post_id;
        sessionStorage.setItem("page_id", JSON.stringify(page_id));
        // Save allEntries back to local storage
    if (!viewedPages.includes(page_id)) {
        viewedPages.push(page_id);
    }
        sessionStorage.setItem("allPages", JSON.stringify(viewedPages));


        let pages = JSON.parse(sessionStorage.getItem("allPages"));
    if (pages.includes(globals.next_link)) {
        $('.single__next__primary').hide();
        $('.single__next__secondary').show();
    }

        /* eslint-enable */

        jQuery(document).ready(function () {
            jQuery('.col__result').theiaStickySidebar({
                additionalMarginTop: 50,
            });

            $('.col__result__btn').on('click', function () {
                $('body').toggleClass('result__open');
            });
        });

        $('.intro__scroll__inner').on('click', function () {
            var x = $(this).offset().top;
            $('html,body').animate({scrollTop: x - 100 }, 1000);
        });

        $(function () {
            $('#configurator__form .gform_body .gsection__wrapper > li').matchHeight();
        });

        $(function () {
            var lis = $('.intro__scroll__animation > span'),
                currentHighlight = 1;
            let N = 0.7;//interval in seconds
            setInterval(function () {
                currentHighlight = (currentHighlight + 1) % lis.length;
                lis.removeClass('active').eq(currentHighlight).addClass('active');
            }, N * 1000);
        });


        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.fancybox').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');

    if ($('body').hasClass('front-page-data') && $(window).width() > 767) {
        if ( document.location.href.indexOf('#home') < 0 ) {
            scrollAnimation('0', 0);

            $('body').addClass('front-page-data-external');

            $('.header__menu__search').css({
                'border-radius' : '0 8px 8px 0',
                });

            setTimeout(function () {
                $('.header__menu__inner').css({
                    'opacity': '1',
                    'transition' : 'opacity 150ms ease-in-out, transform 1000ms ease-in-out, border-radius 1000ms ease-in-out',
                    });

                $('.section1__bg ').css({
                    'transition' : 'height 1000ms ease-in-out',
                    });

                $('.news__slider').css({
                    'opacity': '1',
                })

                $('.intro__text').css({
                    'opacity': '1',
                    });

                $('.header__logo').css({
                    'opacity': '1',
                    });
            }, 300)
        } else {
            setTimeout(function () {
                $('.header__menu__inner').css({
                    'transform': 'translateX(-445px)',
                    });
                $('.header__menu__inner').css({
                    'pointer-events': 'all',
                    });
            }, 1000)

            $('.header__menu__inner').css({
                'opacity': '1',
                });

            $('.header__logo').css({
                'opacity': '1',
                });

            $('.news__slider').css({
                'opacity': '1',
            })

            $('.intro').css({
                'opacity': '1',
                });

            $('.intro__text').css({
                'opacity': '1',
                });

            $(function () {

                if ($('body').hasClass('is-not-mobile') && iev != 11) {
                    $('body').niceScroll({
                        scrollspeed: 200, // scrolling speed
                        mousescrollstep: 20, // scrolling speed with mouse wheel (pixel)
                        smoothscroll: true,
                        autohidemode: false,
                        });
                }
            });

            $('.header__menu__inner').on('mouseenter', function () {
                if (!$('body').hasClass('header__menu__open')) {
                    $('.header__menu__inner').css('transform', 'translateX(-420px)')
                }
            })

            $('.header__menu__inner').on('mouseleave', function () {
                if (!$('body').hasClass('header__menu__open')) {
                    $('.header__menu__inner').css('transform', 'translateX(-445px)')
                }
            })

            $('.header__menu__btn').on('click', function () {
                $('body').toggleClass('header__menu__open');
                $(this).css({
                    'pointer-events': 'none',
                    });
                setTimeout(() => {
                    $(this).css({
                        'pointer-events': 'all',
                        })
                }, 600);
            })
        }
    } else {
        setTimeout(function () {
            $('.header__menu__inner').css({
                'transform': 'translateX(-445px)',
                });
            $('.header__menu__inner').css({
                'pointer-events': 'all',
                });
        }, 1000)

        $(function () {

            if ($('body').hasClass('is-not-mobile') && iev != 11) {
                $('body').niceScroll({
                    scrollspeed: 200, // scrolling speed
                    mousescrollstep: 20, // scrolling speed with mouse wheel (pixel)
                    smoothscroll: true,
                    autohidemode: false,
                    });
            }
        });

        $('.header__menu__inner').on('mouseenter', function () {
            if (!$('body').hasClass('header__menu__open')) {
                $('.header__menu__inner').css('transform', 'translateX(-420px)')
            }
        })

        $('.header__menu__inner').on('mouseleave', function () {
            if (!$('body').hasClass('header__menu__open')) {
                $('.header__menu__inner').css('transform', 'translateX(-445px)')
            }
        })

        $('.header__menu__btn').on('click', function () {
            $('body').toggleClass('header__menu__open');
            $(this).css({
                'pointer-events': 'none',
                });
            setTimeout(() => {
                $(this).css({
                    'pointer-events': 'all',
                    })
            }, 600);
        })
    }


    if ($(window).width() <= 1199) {
        let $goto = $('.goto').attr('href');
        $goto = $goto+'-scroll';
        $('.goto').attr('href', $goto);

        if (window.location.hash) {
            let hash = window.location.hash;
            if (hash.indexOf('scroll') !== -1) {
                let top = $(hash).offset().top;
                top = top - 20;
                $('html,body').animate({
                    scrollTop: top,
                    }, 2000, 'swing');
            }
        }
    }


    if (!$('body').hasClass('page-829-data')) {
        jQuery(document).ready(function () {
            orientationChange();
        });
    }



    function orientationChange()
    {
        if (window.addEventListener) {
            window.addEventListener('orientationchange', function () {
                location.reload();
            });
        }
    }


    /* eslint-disable */

        // Default settings
        let controller = new ScrollMagic.Controller({addIndicators: false});
        let nextTitle;
        let nextTitleAnimation;
    if ( $(window).width() > 0 && $(window).width() <= 1499) {
        nextTitle = $('.single__next__inner');
        nextTitleAnimation = gsap.fromTo(nextTitle, {
            opacity: 0
            }, {
                opacity: 1,
                duration: 1
            });
    } else if ($(window).width() > 1499) {
        nextTitle = $('.single__next__title');
        nextTitleAnimation = gsap.fromTo(nextTitle, {
            x: 0
            }, {
                x: -220,
                duration: 1
            });
    }


        let nextTitleScene = new ScrollMagic.Scene({
            triggerElement: '.single__next',
            duration: '40%',
            triggerHook: 0.5,
            ease: 'none',
        })
            .setTween(nextTitleAnimation)
            // .addIndicators(true)
            .addTo(controller)

        let nextArrow = $('.single__next__arrow');
        let nextArrowAnimation = gsap.fromTo(nextArrow, {
            x: 0
        }, {
            x: 85,
            duration: 1
        });

        let nextArrowScene = new ScrollMagic.Scene({
            triggerElement: '.single__next',
            duration: '40%',
            triggerHook: 0.5,
            ease: 'none',
        })
            .setTween(nextArrowAnimation)
            // .addIndicators(true)
            .addTo(controller)

        let ervaringen = $('.single__ervaringen__inner');
        let ervaringenAnimation = gsap.fromTo(ervaringen, {
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 1
        });

        let ervaringenScene = new ScrollMagic.Scene({
            triggerElement: '.single__ervaringen__inner',
            duration: '40%',
            triggerHook: 0.8,
        })
            .setTween(ervaringenAnimation)
            // .addIndicators(true)
            .addTo(controller)

        let practice = $('.practice__inner');
        let practiceAnimation = gsap.fromTo(practice, {
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 1
        });

        let practiceScene = new ScrollMagic.Scene({
            triggerElement: '.practice__inner',
            duration: '40%',
            triggerHook: 0.8,
        })
            .setTween(practiceAnimation)
            // .addIndicators(true)
            .addTo(controller)


        let footer = $('.footer__logo');
        let footerAnimation = gsap.fromTo(footer, {
            opacity: 0
        }, {
            opacity: 1,
            duration: 1
        });

        let footerScene = new ScrollMagic.Scene({
            triggerElement: '.footer',
            duration: '10%',
            triggerHook: 0.6,
        })
            .setTween(footerAnimation)
            // .addIndicators(true)
            .addTo(controller)


        let footerContact = $('.footer__contact');
        let footerContactAnimation = gsap.fromTo(footerContact, {
            opacity: 0
        }, {
            opacity: 1,
            duration: 1
        });

        let footerContactScene = new ScrollMagic.Scene({
            triggerElement: '.footer',
            duration: '10%',
            triggerHook: 0.5,
        })
            .setTween(footerContactAnimation)
            // .addIndicators(true)
            .addTo(controller)


        document.addEventListener('DOMContentLoaded', function () {
            let langDropdown = document.getElementById('lang-dropdown');
            let langOptions = document.getElementById('lang-options');

            langDropdown.addEventListener('mouseover', function () {
                langOptions.style.display = 'block';
            });

            langDropdown.addEventListener('mouseleave', function () {
                langOptions.style.display = 'none';
            });
        });

        /* eslint-enable */

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
