export default {
    init() {
        /* eslint-disable */
        // JavaScript to be fired on the home page
        jQuery(document).ready(function($) {

            $('.rolstoel__dropdown__top').on('click', function () {
                $(this).closest('.rolstoel__dropdown').toggleClass('open');
            })

            $('.rolstoel__dropdown__bot__item').on('click', function () {
                if(!$(this).hasClass('active')) {
                    $('html, body').animate({
                        scrollTop: $("#info__scroll").offset().top - 100
                    }, 1500);

                    // Remove active class in dropdown and add to clicked one.
                    $('.rolstoel__dropdown').removeClass('open');
                    $('.rolstoel__dropdown__bot__item').removeClass('active');
                    $('.info__item__list').removeClass('active');
                    $(this).addClass('active')

                    // Change name in dropdwon.
                    $('.rolstoel__dropdown__top b').text($(this).text())

                    let $dropdownId = $(this).data('dropdownid');

                    $('.info__item__list').each(function () {
                        let $showId = $(this).data('showid');

                        if($dropdownId == $showId) {
                            $(this).addClass('active');
                        }
                    })

                }
            })



            let controller = new ScrollMagic.Controller({addIndicators: false});


            $('.info__item').each(function () {

                let animation1 = gsap.fromTo($(this), {
                    opacity: 0,
                }, {
                    opacity: 1,
                    duration: 1
                });

                let animation2 = gsap.fromTo($(this).find('.fa-check'), {
                    color: '#f0f0f0',
                }, {
                    color: '#76b82a',
                    duration: 1
                });


                let videoScene1 = new ScrollMagic.Scene({
                    triggerElement: this,
                    duration: '40%',
                    triggerHook: 0.9,
                })
                .setTween(animation1)
                // .addIndicators(true)
                .addTo(controller)

                let videoScene2 = new ScrollMagic.Scene({
                    triggerElement: this,
                    duration: '20%',
                    triggerHook: 0.6,
                })
                    .setTween(animation2)
                    // .addIndicators(true)
                    .addTo(controller)

            })


            let video = $('.need__video');
            let videoAnimation = gsap.fromTo(video, {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 1
            });

            let videoScene = new ScrollMagic.Scene({
                triggerElement: '.need__video',
                duration: '40%',
                triggerHook: 0.9,
            })
                .setTween(videoAnimation)
                // .addIndicators(true)
                .addTo(controller)



            let setup = $('.setup__form');
            let setupAnimation = gsap.fromTo(setup, {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 1
            });

            let setupScene = new ScrollMagic.Scene({
                triggerElement: '.setup__form',
                duration: '40%',
                triggerHook: 0.9,
            })
                .setTween(setupAnimation)
                // .addIndicators(true)
                .addTo(controller)


        });
        /* eslint-enable */
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
