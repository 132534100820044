import {
    magic,
    fadeInRight,
    slideY,
    slideX,
    fadeOut,
    fadeIn,
    magicMobile,
    scrollAnimationBack,
} from './animationFunctions';

import Player from '@vimeo/player';

export default {
    init() {
        /* eslint-disable */
        // JavaScript to be fired on the home page
        jQuery(document).ready(function($) {


            var iframe = $('.section__video__short iframe');
            var player = new Player(iframe);

            function videoPlay(target) {
                target.removeClass('pause')
                target.addClass('play')
                $('.intro').fadeOut();

                player.setVolume(1);
                player.play();
            }

            function videoPause(target) {
                target.removeClass('play')
                target.addClass('pause')
                player.pause();
                $('.intro').fadeIn();
            }


            $(window).on('scroll', function() {
                if($(this).scrollTop() > 1200) {
                    videoPause($('.section__video__btn'));
                }
            });

            $('.section__video__btn').on('click', function () {
                if($(this).hasClass('pause')) {

                    if($(this).hasClass('first')) {
                        $(this).removeClass('first');
                        player.setCurrentTime(0);
                    }

                    videoPlay($(this));

                } else if ($(this).hasClass('play')) {
                    videoPause($(this));
                }
            })


            $('.bol__item .bol__slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                vertical: true,
                arrows: false,
                dots: false,
                adaptiveHeight: true,
                autoplay: true,
                autoplaySpeed: 4000,
            });



            $('.bol__item').each(function () {
                if(!$(this).hasClass('active')) {
                    $(this).find('.bol__slider').slick('slickPause');
                }
            })

            if ($(window).width() <= 1199) {
                let offset1 = $('.bol__item1').offset();
                let offset2 = $('.bol__item2').offset().left + 30;
                let offset3 = $('.bol__item3').offset().left + 30;
                let offset4 = $('.bol__item4').offset();

                // $('.bol__item1 .bol__content').css('left', '-'+offset1.left+'px');
                $('.bol__item2 .bol__content').css('margin-left', '-'+offset2+'px');
                $('.bol__item3 .bol__content').css('margin-left', '-'+offset3+'px');
                // $('.bol__item4 .bol__content').css('left', '-'+offset4.left+'px');
            }

            $('.bol__item').on('hover', function () {
                $('.bol__item').removeClass('active');
                $(this).addClass('active');
                $(this).find('.bol__slider').slick('slickPlay');
            })


            // SCROLLMAGIC start

            if ($(window).width() > 1199) {

                // Default settings
                let controller = new ScrollMagic.Controller({addIndicators: false, vertical: true});
                gsap.defaultEase = Linear.easeNone;

                // Horizontal animation
                let horizontalAnimation
                let pinHorizontal

                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    horizontalAnimation = gsap.to('.horizontal__inner', {ease: "none", x: '-86.01%'});

                    pinHorizontal = new ScrollMagic.Scene({
                        triggerElement: '.horizontal',
                        triggerHook: 'onLeave',
                        duration: '800%',
                    })
                        .setPin('.horizontal')
                        .setTween(horizontalAnimation)
                        .addTo(controller);

                } else if ($(window).width() > 1799) {
                    horizontalAnimation = gsap.to('.horizontal__inner', {ease: "none", x: '-84.38%'});

                    pinHorizontal = new ScrollMagic.Scene({
                        triggerElement: '.horizontal',
                        triggerHook: 'onLeave',
                        duration: '700%',
                    })
                        .setPin('.horizontal')
                        .setTween(horizontalAnimation)
                        .addTo(controller);

                }

                if (window.location.hash == '#gebruikers') {
                    if ( $(window).width() > 1199 && $(window).width() < 1799) {
                        setTimeout(function () {
                            scrollAnimationBack('-23.9215%', 1708.800048828125)
                        }, 300)
                    } else if ($(window).width() > 1799) {
                        setTimeout(function () {
                            scrollAnimationBack('-26.1724%', 2262.39990234375)
                        }, 300)
                    }
                } else if (window.location.hash == '#ons-zitgedrag') {
                    if ( $(window).width() > 1199 && $(window).width() < 1799) {
                        setTimeout(function () {
                            scrollAnimationBack('-36.4759%', 2605.60009765625)
                        }, 300)
                    } else if ($(window).width() > 1799) {
                        setTimeout(function () {
                            scrollAnimationBack('-37.4076%', 3233.60009765625)
                        }, 300)
                    }
                }  else if (window.location.hash == '#onze-zitvisie') {
                    if ( $(window).width() > 1199 && $(window).width() < 1799) {
                        setTimeout(function () {
                            scrollAnimationBack('-59.716%', 4264)
                        }, 300)
                    } else if ($(window).width() > 1799) {
                        setTimeout(function () {
                            scrollAnimationBack('-57.8026%', 5173)
                        }, 300)
                    }
                } else if (window.location.hash == '#wetenschappelijke-zitvisie') {
                    if ( $(window).width() > 1199 && $(window).width() < 1799) {
                        setTimeout(function () {
                            scrollAnimationBack('-48.0575%', 3436)
                        }, 300)
                    } else if ($(window).width() > 1799) {
                        setTimeout(function () {
                            scrollAnimationBack('-47.0048%', 4063.199951171875)
                        }, 300)
                    }
                };



                let bolIntro = document.getElementsByClassName('bol__top')
                let bolIntroAnimation = fadeIn(bolIntro);
                let bolIntroScene
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    bolIntroScene = magic('.horizontal', 110, '40%', bolIntroAnimation, controller);
                } else if ($(window).width() > 1799) {
                    bolIntroScene = magic('.horizontal', 120, '40%', bolIntroAnimation, controller);
                }


                let bol2 = document.getElementsByClassName('bol__item2');
                let bolAnimation2 = fadeInRight(bol2);
                let bolScene2
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    bolScene2 = magic('.horizontal', 85, '30%', bolAnimation2, controller);
                } else if ($(window).width() > 1799) {
                    bolScene2 = magic('.horizontal', 95, '30%', bolAnimation2, controller);
                }

                let bolArrow2 = document.getElementsByClassName('bol__arrow2');
                let bolArrowAnimation2 = fadeInRight(bolArrow2);
                let bolArrowScene2
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    bolArrowScene2 = magic('.horizontal', 100, '30%', bolArrowAnimation2, controller);
                } else if ($(window).width() > 1799) {
                    bolArrowScene2 = magic('.horizontal', 110, '30%', bolArrowAnimation2, controller);
                }

                let bol3 = document.getElementsByClassName('bol__item3');
                let bolAnimation3 = fadeInRight(bol3);
                let bolScene3
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    bolScene3 = magic('.horizontal', 100, '30%', bolAnimation3, controller);
                } else if ($(window).width() > 1799) {
                    bolScene3 = magic('.horizontal', 120, '30%', bolAnimation3, controller);
                }

                let bolArrow4 = document.getElementsByClassName('bol__arrow3');
                let bolArrowAnimation4 = fadeInRight(bolArrow4);
                let bolArrowScene4
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    bolArrowScene4 = magic('.horizontal', 115, '30%', bolArrowAnimation4, controller);
                } else if ($(window).width() > 1799) {
                    bolArrowScene4 = magic('.horizontal', 140, '30%', bolArrowAnimation4, controller);
                }

                // Bol 4
                let bol4 = document.getElementsByClassName('bol__item4');
                let bolAnimation4 = fadeInRight(bol4);
                let bolScene4
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    bolScene4 = magic('.horizontal', 115, '30%', bolAnimation4, controller);
                } else if ($(window).width() > 1799) {
                    bolScene4 = magic('.horizontal', 135, '30%', bolAnimation4, controller);
                }

                // Flexibel slide container
                let flexibel = document.getElementsByClassName('central__slider');
                let flexibelAnimation = slideY(flexibel, '-350px', '600px');
                let flexibelScene
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    flexibelScene = magic('.horizontal', 235, '80%', flexibelAnimation, controller);
                } else if ($(window).width() > 1799) {
                    flexibelScene = magic('.horizontal', 210, '80%', flexibelAnimation, controller);
                }

                // Flexibel slide text
                let flexibelText = document.getElementsByClassName('flexibel__text');
                let flexibelTextAnimation = fadeInRight(flexibelText);
                let flexibelTextScene
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    flexibelTextScene = magic('.horizontal', 170, '40%', flexibelTextAnimation, controller);
                } else if ($(window).width() > 1799) {
                    flexibelTextScene = magic('.horizontal', 190, '40%', flexibelTextAnimation, controller);
                }

                let flexibelTextTitle = $('.flexibel__text h2');
                let flexibelTextTitleAnimation = fadeInRight(flexibelTextTitle);
                let flexibelTextTitleScene
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    flexibelTextTitleScene = magic('.horizontal', 180, '30%', flexibelTextTitleAnimation, controller);
                } else if ($(window).width() > 1799) {
                    flexibelTextTitleScene = magic('.horizontal', 200, '30%', flexibelTextTitleAnimation, controller);
                }


                // Zitgedrag text
                let zitgedrag = document.getElementsByClassName('zitgedrag__text')
                let zitgedragAnimation = fadeInRight(zitgedrag);
                let zitgedragScene
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    zitgedragScene = magic('.horizontal', 290, '40%', zitgedragAnimation, controller);
                } else if ($(window).width() > 1799) {
                    zitgedragScene = magic('.horizontal', 268, '40%', zitgedragAnimation, controller);
                }

                let zitgedragTextTitle = $('.zitgedrag__text h3');
                let zitgedragTextTitleAnimation = fadeInRight(zitgedragTextTitle);
                let zitgedragTextTitleScene;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    zitgedragTextTitleScene = magic('.horizontal', 300, '30%', zitgedragTextTitleAnimation, controller);
                } else if ($(window).width() > 1799) {
                    zitgedragTextTitleScene = magic('.horizontal', 278, '30%', zitgedragTextTitleAnimation, controller);
                }

                // Zitgedrag img1
                let zitgedragImg1 = document.getElementsByClassName('zitgedrag__img1')
                let zitgedragImg1Animation = fadeIn(zitgedragImg1);
                let zitgedragImg1Scene
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    zitgedragImg1Scene = magic('.horizontal', 360, '30%', zitgedragImg1Animation, controller);
                } else if ($(window).width() > 1799) {
                    zitgedragImg1Scene = magic('.horizontal', 320, '30%', zitgedragImg1Animation, controller);
                }

                // Zitgedrag img2
                let zitgedragImg2 = document.getElementsByClassName('zitgedrag__img2')
                let zitgedragImg2Animation = fadeIn(zitgedragImg2);
                let zitgedragImg2Scene
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    zitgedragImg2Scene = magic('.horizontal', 370, '30%', zitgedragImg2Animation, controller);
                } else if ($(window).width() > 1799) {
                    zitgedragImg2Scene = magic('.horizontal', 330, '30%', zitgedragImg2Animation, controller);
                }

                // Weteschappelijke zitvisie text
                let wetenschap = document.getElementsByClassName('wetenschap__text')
                let wetenschapAnimation = fadeInRight(wetenschap);
                let wetenschapScene
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    wetenschapScene = magic('.horizontal', 430, '40%', wetenschapAnimation, controller);
                } else if ($(window).width() > 1799) {
                    wetenschapScene = magic('.horizontal', 380, '40%', wetenschapAnimation, controller);
                }

                let wetenschapTitle = $('.wetenschap__text h3');
                let wetenschapTitleAnimation = fadeInRight(wetenschapTitle);
                let wetenschapTextTitleScene;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    wetenschapTextTitleScene = magic('.horizontal', 440, '30%', wetenschapTitleAnimation, controller);
                } else if ($(window).width() > 1799) {
                    wetenschapTextTitleScene = magic('.horizontal', 390, '30%', wetenschapTitleAnimation, controller);
                }

                // Weteschappelijke zitvisie img
                let wetenschapImg = document.getElementsByClassName('wetenschap__image')
                let wetenschapImgAnimation = fadeIn(wetenschapImg);
                let zwetenschapImgScene
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    zwetenschapImgScene = magic('.horizontal', 430, '30%', wetenschapImgAnimation, controller);
                } else if ($(window).width() > 1799) {
                    zwetenschapImgScene = magic('.horizontal', 380, '30%', wetenschapImgAnimation, controller);
                }


                // Zitvisie image1
                let zitvisieImage1 = document.getElementsByClassName('zitvisie__image1')
                let zitvisieImageAnimation1 = fadeIn(zitvisieImage1);
                let zitvisieImageScene1
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    zitvisieImageScene1 = magic('.horizontal', 550, '30%', zitvisieImageAnimation1, controller);
                } else if ($(window).width() > 1799) {
                    zitvisieImageScene1 = magic('.horizontal', 465, '30%', zitvisieImageAnimation1, controller);
                }

                // Zitvisie image
                let zitvisieImage = document.getElementsByClassName('zitvisie__image2')
                let zitvisieImageAnimation = fadeOut(zitvisieImage);
                let zitvisieImageScene
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    zitvisieImageScene = magic('.horizontal', 550, '30%', zitvisieImageAnimation, controller);
                } else if ($(window).width() > 1799) {
                    zitvisieImageScene = magic('.horizontal', 465, '30%', zitvisieImageAnimation, controller);
                }


                // Zitvisie text
                let zitvisieText = document.getElementsByClassName('zitvisie__text')
                let zitvisieTextAnimation = fadeInRight(zitvisieText);
                let zitvisieTextScene
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    zitvisieTextScene = magic('.horizontal', 555, '40%', zitvisieTextAnimation, controller);
                } else if ($(window).width() > 1799) {
                    zitvisieTextScene = magic('.horizontal', 488, '40%', zitvisieTextAnimation, controller);
                }

                let zitvisieTextTitle = $('.zitvisie__text h4');
                let zitvisieTextTitleAnimation = fadeInRight(zitvisieTextTitle);
                let zitvisieTextTitleScene;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    zitvisieTextTitleScene = magic('.horizontal', 565, '30%', zitvisieTextTitleAnimation, controller);
                } else if ($(window).width() > 1799) {
                    zitvisieTextTitleScene = magic('.horizontal', 498, '30%', zitvisieTextTitleAnimation, controller);
                }

                // Voordelen image
                let voordelenImage = document.getElementsByClassName('voordelen__image')
                let voordelenImageAnimation = fadeIn(voordelenImage);
                let voordelenImageScene
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    voordelenImageScene = magic('.horizontal', 660, '30%', voordelenImageAnimation, controller);
                } else if ($(window).width() > 1799) {
                    voordelenImageScene = magic('.horizontal', 565, '30%', voordelenImageAnimation, controller);
                }

                // Voordelen text
                let voordelenText = document.getElementsByClassName('voordelen__text')
                let voordelenTextAnimation = fadeInRight(voordelenText);
                let voordelenTextScene
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    voordelenTextScene = magic('.horizontal', 690, '40%', voordelenTextAnimation, controller);
                } else if ($(window).width() > 1799) {
                    voordelenTextScene = magic('.horizontal', 585, '40%', voordelenTextAnimation, controller);
                }

                let voordelenTextTitle = $('.voordelen__text h4');
                let voordelenTextTitleAnimation = fadeInRight(voordelenTextTitle);
                let voordelenTextTitleScene;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    voordelenTextTitleScene = magic('.horizontal', 700, '30%', voordelenTextTitleAnimation, controller);
                } else if ($(window).width() > 1799) {
                    voordelenTextTitleScene = magic('.horizontal', 595, '30%', voordelenTextTitleAnimation, controller);
                }

                let $voordelenList = $('.voordelen__list__item');
                let $timing = 550;
                $voordelenList.each(function () {
                    let voordelenListItem = $(this);
                    let voordelenListItemAnimation = fadeIn(voordelenListItem);
                    let voordelenListAnimationScene;
                    if ( $(window).width() > 1199 && $(window).width() < 1799) {
                        // $timing = 720;
                        voordelenListAnimationScene = magic('.horizontal', $timing, '30%', voordelenListItemAnimation, controller);
                    } else if ($(window).width() > 1799) {
                        // $timing = 625;
                        voordelenListAnimationScene = magic('.horizontal', $timing, '30%', voordelenListItemAnimation, controller);
                    }
                    $timing = $timing + 10;
                })


                // Footer: Animation
                let next = document.getElementsByClassName('next');
                let nextAnimation = fadeInRight(next);
                let nextScene
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    nextScene = magic('.horizontal', 770, '40%', nextAnimation, controller);
                } else if ($(window).width() > 1799) {
                    nextScene = magic('.horizontal', 640, '40%', nextAnimation, controller);
                }


                let nextTitle = document.getElementsByClassName('next__go');
                let nextTitleAnimation = fadeInRight(nextTitle);
                let nextTitleScene;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    nextTitleScene = magic('.horizontal', 780, '30%', nextTitleAnimation, controller);
                } else if ($(window).width() > 1799) {
                    nextTitleScene = magic('.horizontal', 650, '30%', nextTitleAnimation, controller);
                }


            } else {

                // Default settings
                let controller = new ScrollMagic.Controller({addIndicators: false, vertical: true});
                gsap.defaultEase = Linear.easeNone;

                let flexibelText = document.getElementsByClassName('flexibel__text');
                let flexibelTextAnimation = fadeIn(flexibelText);
                let flexibelTextScene = magicMobile('.flexibel__text', 0.9, 0, '30%', flexibelTextAnimation, controller);

                let centralFade = document.getElementsByClassName('central__slider__inner');
                let centralFadeAnimation = fadeIn(centralFade);
                let centralFadeScene = magicMobile('.central__slider',  0.9, 0, '30%', centralFadeAnimation, controller);

                let flexibel = document.getElementsByClassName('central__slider');
                let flexibelAnimation;
                let flexibelScene;
                if ( $(window).width() > 767 && $(window).width() <= 1199) {
                    flexibelAnimation = slideX(flexibel, '0', '-42%');
                    flexibelScene = magicMobile('.central__slider', 0.6 , 0, '50%', flexibelAnimation, controller);
                } else if ($(window).width() <= 767) {
                    flexibelAnimation = slideX(flexibel, '0', '-100%');
                    flexibelScene = magicMobile('.central__slider', 0.5 , 0, '40%', flexibelAnimation, controller);
                }

                let zitgedragText = document.getElementsByClassName('zitgedrag__text');
                let zitgedragTextAnimation = fadeIn(zitgedragText);
                let zitgedragTextScene = magicMobile('.zitgedrag__text', 0.9, 0, '30%', zitgedragTextAnimation, controller);

                let zitgedragImages = document.getElementsByClassName('zitgedrag__images');
                let zitgedragImagesAnimation = fadeIn(zitgedragImages);
                let zitgedragImagesScene = magicMobile('.zitgedrag__images', 0.9, 0, '30%', zitgedragImagesAnimation, controller);

                let wetenschapImage = document.getElementsByClassName('wetenschap__image');
                let wetenschapImageAnimation = fadeIn(wetenschapImage);
                let wetenschapImageScene = magicMobile('.wetenschap__image', 0.9, 0, '30%', wetenschapImageAnimation, controller);

                let wetenschapText = document.getElementsByClassName('wetenschap__text');
                let wetenschapTextAnimation = fadeIn(wetenschapText);
                let wetenschapTextScene = magicMobile('.wetenschap__text', 0.9, 0, '30%', wetenschapTextAnimation, controller);

                let zitvisieImage1 = document.getElementsByClassName('zitvisie__image1')
                let zitvisieImageAnimation1 = fadeIn(zitvisieImage1);
                let zitvisieImageScene1 = magicMobile('.zitvisie__image1', 0.9, 0, '30%', zitvisieImageAnimation1, controller);

                let zitvisieImage = document.getElementsByClassName('zitvisie__image2')
                let zitvisieImageAnimation = fadeOut(zitvisieImage);
                let zitvisieImageScene = magicMobile('.zitvisie__image2', 0.9, 0, '30%', zitvisieImageAnimation, controller);

                let zitvisieText = document.getElementsByClassName('zitvisie__text');
                let zitvisieTextAnimation = fadeIn(zitvisieText);
                let zitvisieTextScene = magicMobile('.zitvisie__text', 0.9, 0, '30%', zitvisieTextAnimation, controller);

                let voordelenTitle = $('.voordelen__text h4');
                let voordelenTitleAnimation = fadeIn(voordelenTitle);
                let voordelenTitleScene = magicMobile('.voordelen__list', 0.9, 0, '10%', voordelenTitleAnimation, controller);

                let voordelenList = $('.voordelen__list__item');
                let timing = 0;
                voordelenList.each(function () {
                    let voordelenListItem = $(this);
                    let voordelenListItemAnimation = fadeIn(voordelenListItem);
                    let voordelenListAnimationScene = magicMobile('.voordelen__list', 0.9, timing, '10%', voordelenListItemAnimation, controller);

                    timing = timing + 5;
                })

                let voordelenImage = $('.voordelen__image');
                let voordelenImageAnimation = fadeIn(voordelenImage);
                let voordelenImageScene = magicMobile('.voordelen__image', 0.9, 0, '30%', voordelenImageAnimation, controller);

                let last = $('.next');
                let lastAnimation = fadeIn(last);
                let lastImageScene = magicMobile('.section__last', 0.9, 0, '30%', lastAnimation, controller);


            }


        });
        /* eslint-enable */
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
