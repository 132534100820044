export default {
    init() {
        /* eslint-disable */
        // JavaScript to be fired on the home page
        jQuery(document).ready(function($) {

            let controller = new ScrollMagic.Controller({addIndicators: false});

            let wist = $('.facts__container');
            let wistAnimation = gsap.fromTo(wist, {
                y: 150,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                duration: 1
            });

            let wistScene = new ScrollMagic.Scene({
                triggerElement: '.facts__container',
                duration: '60%',
                triggerHook: 1,
            })
                .setTween(wistAnimation)
                // .addIndicators(true)
                .addTo(controller)


            let facts = $('.facts__animation');
            let factsAnimation = gsap.fromTo(facts, {
                y: 150,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                duration: 1
            });

            let factsScene = new ScrollMagic.Scene({
                triggerElement: '.facts__animation',
                duration: '60%',
                triggerHook: 1,
            })
                .setTween(factsAnimation)
                // .addIndicators(true)
                .addTo(controller)


            let image = $('.image__animation');
            let imageAnimation = gsap.fromTo(image, {
                y: 150,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                duration: 1
            });

            let imageScene = new ScrollMagic.Scene({
                triggerElement: '.image__animation',
                duration: '60%',
                triggerHook: 1,
            })
                .setTween(imageAnimation)
                // .addIndicators(true)
                .addTo(controller)




            let discomfortOffset = 0;
            $('.discomfort__block').each(function () {
                let animation = gsap.fromTo($(this), {
                    opacity: 0,
                }, {
                    opacity: 1,
                    duration: 1
                });

                let videoScene = new ScrollMagic.Scene({
                    triggerElement: this,
                    duration: '40%',
                    triggerHook: 0.9,
                    offset: discomfortOffset,
                })
                    .setTween(animation)
                    // .addIndicators(true)
                    .addTo(controller)


                discomfortOffset += 100;
            })






        });
        /* eslint-enable */
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
