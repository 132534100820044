export default {
    init() {
        /* eslint-disable */
        // JavaScript to be fired on the home page
        jQuery(document).ready(function($) {

            let controller = new ScrollMagic.Controller({addIndicators: false});

            let users = $('.verhaal__inner');
            let usersAnimation = gsap.fromTo(users, {
                y: 150,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                duration: 1
            });

            let usersScene = new ScrollMagic.Scene({
                triggerElement: '.verhaal__inner',
                duration: '60%',
                triggerHook: 1,
            })
                .setTween(usersAnimation)
                // .addIndicators(true)
                .addTo(controller)

            let balans = $('.balans');
            let balansAnimation = gsap.fromTo(balans, {
                y: 150,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                duration: 1
            });

            let balansScene = new ScrollMagic.Scene({
                triggerElement: '.balans',
                duration: '60%',
                triggerHook: 1,
            })
                .setTween(balansAnimation)
                // .addIndicators(true)
                .addTo(controller)





        });
        /* eslint-enable */
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
