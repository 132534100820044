import {
    magic,
    scrollAnimation,
    fadeInRight,
    bottomToTop,
    topToBottom,
    fadeIn,
    magicMobile,
    scrollAnimationBack,
} from './animationFunctions'

export default {
    init() {
        /* eslint-disable */
        // JavaScript to be fired on the home page
        jQuery(document).ready(function($) {

            $('.meten__block__tooltip').tooltip();

            // Step 4, on video mouseenter play.
            $('.instellen__item__video').on('mouseenter', function () {
                $(this).find('video').trigger('play');
            })

            // Step 4, on video mouseleave pause.
            $('.instellen__item__video').on('mouseleave', function () {
                $(this).find('video').trigger('pause');
            })



            if ($(window).width() > 1199) {

                // Anchor scroll animations
                $('#scroll__id__1').on('click',function() {
                    if ( $(window).width() > 1199 && $(window).width() < 1799) {
                        scrollAnimation('-9.8229%', 656)
                    } else if ($(window).width() > 1799) {
                        scrollAnimation('-13.9211%', 1220)
                    }
                });

                $('#scroll__id__2').on('click',function() {
                    if ( $(window).width() > 1199 && $(window).width() < 1799) {
                        scrollAnimation('-20.2448%', 1352)
                    } else if ($(window).width() > 1799) {
                        scrollAnimation('-22.3651%', 1960)
                    }
                });

                $('#scroll__id__3').on('click',function() {
                    if ( $(window).width() > 1199 && $(window).width() < 1799) {
                        scrollAnimation('-2080%', 2080)
                    } else if ($(window).width() > 1799) {
                        scrollAnimation('-34.4605%', 3020)
                    }
                });

                $('#newton-instellen-to').on('click',function() {
                    if ( $(window).width() > 1199 && $(window).width() < 1799) {
                        scrollAnimation('-43.724%', 2920)
                    } else if ($(window).width() > 1799) {
                        scrollAnimation('-45.5608%', 3992.800048828125)
                    }
                });

                $('#scroll__id__5').on('click',function() {
                    if ( $(window).width() > 1199 && $(window).width() < 1799) {
                        scrollAnimation('-63.1683%', 4214.39990234375)
                    } else if ($(window).width() > 1799) {
                        scrollAnimation('-62.4743%', 5468.7998046875)
                    }
                });

                // SCROLLMAGIC start

                // Default settings
                let controller = new ScrollMagic.Controller({addIndicators: false, vertical: true});
                gsap.defaultEase = Linear.easeNone;

                let horizontalAnimation
                let pinHorizontal


                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    // Horizontal animation
                    horizontalAnimation = gsap.to('.horizontal__inner', {ease: "none", x: '-80.5%'});

                    pinHorizontal = new ScrollMagic.Scene({
                        triggerElement: '.horizontal',
                        triggerHook: 'onLeave',
                        duration: '700%',
                    })
                    .setPin('.horizontal')
                    .setTween(horizontalAnimation)
                    .addTo(controller);

                } else if ($(window).width() > 1799) {
                    // Horizontal animation
                     horizontalAnimation = gsap.to('.horizontal__inner', {ease: "none", x: '-83.23%'});

                    pinHorizontal = new ScrollMagic.Scene({
                        triggerElement: '.horizontal',
                        triggerHook: 'onLeave',
                        duration: '700%',
                    })
                        .setPin('.horizontal')
                        .setTween(horizontalAnimation)
                        .addTo(controller);
                }

                if (window.location.hash == '#newton-instellen') {
                    if ( $(window).width() > 1199 && $(window).width() < 1799) {
                        setTimeout(function () {
                            scrollAnimationBack('-43.724%', 2920)
                        }, 300)
                    } else if ($(window).width() > 1799) {
                        setTimeout(function () {
                            scrollAnimationBack('-45.7768%', 4151)
                        }, 300)
                    }
                };



                // Step 1: Animation
                let number1 = $('.number1 .number__number');
                let numberAnimation1 = fadeIn(number1);
                let numberScene1;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    numberScene1 = magic('.horizontal', 95, '30%', numberAnimation1, controller);
                } else if ($(window).width() > 1799) {
                    numberScene1 = magic('.horizontal', 110, '30%', numberAnimation1, controller);
                }

                // Step 1: Animation
                let numberImg1 = $('.number1 .number__img');
                let numberImgAnimation1 = fadeIn(numberImg1);
                let numberImgScene1;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    numberImgScene1 = magic('.horizontal', 95, '30%', numberImgAnimation1, controller);
                } else if ($(window).width() > 1799) {
                    numberImgScene1 = magic('.horizontal', 110, '30%', numberImgAnimation1, controller);
                }

                let numberText1 = document.getElementsByClassName('number__text1');
                let numberTextAnimation1 = fadeInRight(numberText1);
                let numberTextScene1;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    numberTextScene1 = magic('.horizontal', 95, '40%', numberTextAnimation1, controller);
                } else if ($(window).width() > 1799) {
                    numberTextScene1 = magic('.horizontal', 110, '40%', numberTextAnimation1, controller);
                }

                let numberTextTitle1 = $('.number__text1 h3');
                let numberTextTitleAnimation1 = fadeInRight(numberTextTitle1);
                let numberTextTitleScene1;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    numberTextTitleScene1 = magic('.horizontal', 105, '30%', numberTextTitleAnimation1, controller);
                } else if ($(window).width() > 1799) {
                    numberTextTitleScene1 = magic('.horizontal', 120, '30%', numberTextTitleAnimation1, controller);
                }

                // Step 2: Animation
                let meten = $('.meten__block');
                let metenTiming = 160;
                meten.each(function () {
                    let metenAnimation = fadeIn($(this));
                    let metenScene;
                    if ( $(window).width() > 1199 && $(window).width() < 1799) {
                        metenScene = magic('.horizontal', metenTiming, '30%', metenAnimation, controller);
                    } else if ($(window).width() > 1799) {
                        metenScene = magic('.horizontal', metenTiming, '30%', metenAnimation, controller);
                    }
                    metenTiming += 10;
                })

                let number2 = $('.number2 .number__number');
                let numberAnimation2 = fadeIn(number2);
                let numberScene2;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    numberScene2 = magic('.horizontal', 185, '30%', numberAnimation2, controller);
                } else if ($(window).width() > 1799) {
                    numberScene2 = magic('.horizontal', 195, '30%', numberAnimation2, controller);
                }

                let numberText2 = document.getElementsByClassName('number__text2');
                let numberTextAnimation2 = fadeInRight(numberText2);
                let numberTextScene2;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    numberTextScene2 = magic('.horizontal', 185, '40%', numberTextAnimation2, controller);
                } else if ($(window).width() > 1799) {
                    numberTextScene2 = magic('.horizontal', 195, '40%', numberTextAnimation2, controller);
                }

                let numberTextTitle2 = $('.number__text2 h3');
                let numberTextTitleAnimation2 = fadeInRight(numberTextTitle2);
                let numberTextTitleScene2;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    numberTextTitleScene2 = magic('.horizontal', 195, '30%', numberTextTitleAnimation2, controller);
                } else if ($(window).width() > 1799) {
                    numberTextTitleScene2 = magic('.horizontal', 205, '30%', numberTextTitleAnimation2, controller);
                }

                // Step 3: Animation
                let newtonIdImg = document.getElementsByClassName('id__image');
                let newtonIdImgAnimation = fadeIn(newtonIdImg);
                let newtonIdImgScene;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    newtonIdImgScene = magic('.horizontal', 255, '30%', newtonIdImgAnimation, controller);
                } else if ($(window).width() > 1799) {
                    newtonIdImgScene = magic('.horizontal', 265, '30%', newtonIdImgAnimation, controller);
                }

                let number3 = $('.number3 .number__number');
                let numberAnimation3 = fadeIn(number3);
                let numberScene3;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    numberScene3 = magic('.horizontal', 280, '30%', numberAnimation3, controller);
                } else if ($(window).width() > 1799) {
                    numberScene3 = magic('.horizontal', 300, '30%', numberAnimation3, controller);
                }

                let numberText3 = document.getElementsByClassName('number__text3');
                let numberTextAnimation3 = fadeInRight(numberText3);
                let numberTextScene3;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    numberTextScene3 = magic('.horizontal', 280, '40%', numberTextAnimation3, controller);
                } else if ($(window).width() > 1799) {
                    numberTextScene3 = magic('.horizontal', 300, '40%', numberTextAnimation3, controller);
                }

                let numberTextTitle3 = $('.number__text3 h3');
                let numberTextTitleAnimation3 = fadeInRight(numberTextTitle3);
                let numberTextTitleScene3;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    numberTextTitleScene3 = magic('.horizontal', 290, '30%', numberTextTitleAnimation3, controller);
                } else if ($(window).width() > 1799) {
                    numberTextTitleScene3 = magic('.horizontal', 310, '30%', numberTextTitleAnimation3, controller);
                }

                // Step 4: Animation

                let instellenTop = $('.instellen__row--top .instellen__item');
                let instellenTopTiming = 365;
                instellenTop.each(function () {
                    let instellenAnimation = fadeIn($(this));
                    let instellenScene;
                    if ( $(window).width() > 1199 && $(window).width() < 1799) {
                        instellenScene = magic('.horizontal', instellenTopTiming, '30%', instellenAnimation, controller);
                    } else if ($(window).width() > 1799) {
                        instellenScene = magic('.horizontal', instellenTopTiming, '30%', instellenAnimation, controller);
                    }
                    instellenTopTiming += 10;
                })

                let instellenBot = $('.instellen__row--bot .instellen__item');
                let instellenBotTiming = 375;
                instellenBot.each(function () {
                    let instellenAnimation = fadeIn($(this));
                    let instellenScene;
                    if ( $(window).width() > 1199 && $(window).width() < 1799) {
                        instellenScene = magic('.horizontal', instellenBotTiming, '30%', instellenAnimation, controller);
                    } else if ($(window).width() > 1799) {
                        instellenScene = magic('.horizontal', instellenBotTiming, '30%', instellenAnimation, controller);
                    }
                    instellenBotTiming += 10;
                })

                let numberText4 = document.getElementsByClassName('number__text4');
                let numberTextAnimation4 = fadeInRight(numberText4);
                let numberTextScene4 = magic('.horizontal', 400, '30%', numberTextAnimation4, controller);

                let numberTextTitle4 = $('.number__text4 h3');
                let numberTextTitleAnimation4 = fadeInRight(numberTextTitle4);
                let numberTextTitleScene4 = magic('.horizontal', 400, '30%', numberTextTitleAnimation4, controller);

                let number4 = $('.number4 .number__number');
                let numberAnimation4 = fadeIn(number4);
                let numberScene4 = magic('.horizontal', 400, '30%', numberAnimation4, controller);

                // Wist je dat: Animation
                let wist = document.getElementsByClassName('balloon');
                let wistAnimation = fadeIn(wist);
                let wistScene = magic('.horizontal', 470, '30%', wistAnimation, controller);

                // Step 5: Animation
                let numberText5 = document.getElementsByClassName('number__text5');
                let numberTextAnimation5 = fadeInRight(numberText5);
                let numberTextScene5;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    numberTextScene5 = magic('.horizontal', 535, '40%', numberTextAnimation5, controller);
                } else if ($(window).width() > 1799) {
                    numberTextScene5 = magic('.horizontal', 525, '40%', numberTextAnimation5, controller);
                }

                let numberTextTitle5 = $('.number__text5 h3');
                let numberTextTitleAnimation5 = fadeInRight(numberTextTitle5);
                let numberTextTitleScene5;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    numberTextTitleScene5 = magic('.horizontal', 545, '30%', numberTextTitleAnimation5, controller);
                } else if ($(window).width() > 1799) {
                    numberTextTitleScene5 = magic('.horizontal', 535, '30%', numberTextTitleAnimation5, controller);
                }

                let number5 = $('.section7__2 .number__number');
                let numberAnimation5 = fadeIn(number5);
                let numberScene5;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    let numberScene5 = magic('.horizontal', 535, '30%', numberAnimation5, controller);
                } else if ($(window).width() > 1799) {
                    let numberScene5 = magic('.horizontal', 525, '30%', numberAnimation5, controller);
                }

                let aanpassenBot = document.getElementsByClassName('aanpassen__bot__animation');
                let aanpassenBotAnimation = topToBottom(aanpassenBot);
                let aanpassenBotScene;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    aanpassenBotScene = magic('.horizontal', 560, '60%', aanpassenBotAnimation, controller);
                } else if ($(window).width() > 1799) {
                    aanpassenBotScene = magic('.horizontal', 535, '45%', aanpassenBotAnimation, controller);
                }

                let aanpassenTop = document.getElementsByClassName('aanpassen__top__animation');
                let aanpassenTopAnimation = bottomToTop(aanpassenTop);
                let aanpassenTopScene;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    aanpassenTopScene = magic('.horizontal', 600, '60%', aanpassenTopAnimation, controller);
                } else if ($(window).width() > 1799) {
                    aanpassenTopScene = magic('.horizontal', 575, '45%', aanpassenTopAnimation, controller);
                }

                // Footer: Animation
                let next = document.getElementsByClassName('next');
                let nextAnimation = fadeInRight(next);
                let nextScene;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    let nextScene = magic('.horizontal', 675, '30%', nextAnimation, controller);
                } else if ($(window).width() > 1799) {
                    let nextScene = magic('.horizontal', 655, '30%', nextAnimation, controller);
                }

                let nextTitle = document.getElementsByClassName('next__go');
                let nextTitleAnimation = fadeInRight(nextTitle);
                let nextTitleScene;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    nextTitleScene = magic('.horizontal', 675, '30%', nextTitleAnimation, controller);
                } else if ($(window).width() > 1799) {
                    nextTitleScene = magic('.horizontal', 655, '30%', nextTitleAnimation, controller);
                }


            } else {

                if ($(window).width() < 991) {
                    $('.instellen__row--bot .instellen__item').each(function () {
                        let $video = $(this).get(0).outerHTML;
                        $('.instellen__row--top .instellen__video').append($video)
                    })
                }

                $('.intro__to span').on('click',function(e) {
                    e.preventDefault();
                    let offset
                    let target = $(this).attr('id') + 'goto';

                    if($(this).attr('id') == 'scroll__id__1') {
                        offset = 0;
                    } else {
                        offset = 40;
                    }

                    if ($(this).data('offset') != undefined) offset = $(this).data('offset');
                    $('html, body').stop().animate({
                        'scrollTop': $('#'+target).offset().top - offset
                    }, 1500, 'swing', function() {
                        // window.location.hash = target;
                    });
                });

                // Default settings
                let controller = new ScrollMagic.Controller({addIndicators: false, vertical: true});
                gsap.defaultEase = Linear.easeNone;

                let number1 = $('.number1 .number__number');
                let number1Animation = fadeIn(number1);
                let number1Scene = magicMobile('.number1 .number__number', 0.9, 0, '30%', number1Animation, controller);

                let numberImg1 = $('.number1 .number__img');
                let numberImg1Animation = fadeIn(numberImg1);
                let numberImg1Scene = magicMobile('.number1 .number__img', 0.9, 0, '30%', numberImg1Animation, controller);

                let number1Text = $('.number__text1');
                let number1TextAnimation = fadeIn(number1Text);
                let number1TextScene = magicMobile('.number__text1', 0.9, 0, '30%', number1TextAnimation, controller);

                let child1 = $('.meten__block:nth-child(1)');
                let child1Animation = fadeIn(child1);
                let child1Scene = magicMobile('.meten', 0.9, 0, '30%', child1Animation, controller);

                let child2 = $('.meten__block:nth-child(2)');
                let child2Animation = fadeIn(child2);
                let child2Scene = magicMobile('.meten', 0.85, 0, '30%', child2Animation, controller);

                let child3 = $('.meten__block:nth-child(3)');
                let child3Animation = fadeIn(child3);
                let child3Scene = magicMobile('.meten', 0.8, 0, '30%', child3Animation, controller);

                let child4 = $('.meten__block:nth-child(4)');
                let child4Animation = fadeIn(child4);
                let child4Scene = magicMobile('.meten', 0.75, 0, '30%', child4Animation, controller);



                let number2 = $('.number2 .number__number');
                let number2Animation = fadeIn(number2);
                let number2Scene = magicMobile('.number2 .number__number', 0.9, 0, '30%', number2Animation, controller);

                let number2Text = $('.number__text2');
                let number2TextAnimation = fadeIn(number2Text);
                let number2TextScene = magicMobile('.number__text2', 0.9, 0, '30%', number2TextAnimation, controller);

                let number3Image = $('.id__image');
                let number3ImageAnimation = fadeIn(number3Image);
                let number3ImageScene = magicMobile('.id__image', 0.9, 0, '50%', number3ImageAnimation, controller);

                let number3 = $('.number3 .number__number');
                let number3Animation = fadeIn(number3);
                let number3Scene = magicMobile('.number3 .number__number', 0.9, 0, '30%', number3Animation, controller);

                let number3Text = $('.number__text3');
                let number3TextAnimation = fadeIn(number3Text);
                let number3TextScene = magicMobile('.number__text3', 0.9, 0, '30%', number3TextAnimation, controller);

                let number4 = $('.number4 .number__number');
                let number4Animation = fadeIn(number4);
                let number4Scene = magicMobile('.number4 .number__number', 0.9, 0, '30%', number4Animation, controller);

                let number4Text = $('.number__text4');
                let number4TextAnimation = fadeIn(number4Text);
                let number4TextScene = magicMobile('.number__text4', 0.9, 0, '30%', number4TextAnimation, controller);

                $('.instellen__item').each(function () {
                    let instellenItem = this;
                    let instellenItemAnimation = fadeIn(instellenItem);
                    let instellenItemScene = magicMobile(this, 0.9, 0, '30%', instellenItemAnimation, controller);
                })

                let wist = $('.wist');
                let wistAnimation = fadeIn(wist);
                let wistScene = magicMobile('.section6__2', 0.9, 0, '30%', wistAnimation, controller);

                let number5 = $('.section7__2 .number__number');
                let number5Animation = fadeIn(number5);
                let number5Scene = magicMobile('.section7__2 .number__number', 0.9, 0, '30%', number5Animation, controller);

                let number5Text = $('.number__text5');
                let number5TextAnimation = fadeIn(number5Text);
                let number5TextScene = magicMobile('.number__text5', 0.9, 0, '30%', number5TextAnimation, controller);

                let aanpassenTopImage = $('.aanpassen__top .aanpassen__img');
                let aanpassenTopImageAnimation = fadeIn(aanpassenTopImage);
                let aanpassenTopImageScene = magicMobile('.aanpassen__top .aanpassen__img', 0.9, 0, '30%', aanpassenTopImageAnimation, controller);

                let aanpassenBotImage = $('.aanpassen__bot__img');
                let aanpassenBotImageAnimation = fadeIn(aanpassenBotImage);
                let aanpassenBotImageScene = magicMobile('.aanpassen__bot__img', 0.9, 0, '30%', aanpassenBotImageAnimation, controller);

                let last = $('.next');
                let lastAnimation = fadeIn(last);
                let lastImageScene = magicMobile('.section__last', 0.9, 0, '30%', lastAnimation, controller);



            }


        });
        /* eslint-enable */
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
