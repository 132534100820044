export default {
    init() {
        /* eslint-disable */
        // JavaScript to be fired on the home page
        jQuery(document).ready(function($) {

            $('.user__list a[href*="#"]:not([href="#"])').on('click',function(e) {
                e.preventDefault();
                var offset = 250;
                var target = this.hash;
                if ($(this).data('offset') != undefined) offset = $(this).data('offset');
                $('html, body').stop().animate({
                    'scrollTop': $(target).offset().top - offset
                }, 1500, 'swing', function() {
                    // window.location.hash = target;
                });
            });


            // Default settings
            let controller = new ScrollMagic.Controller({addIndicators: false});

            let users = $('.user__list');
            let usersAnimation = gsap.fromTo(users, {
                y: 150,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                duration: 1
            });

            let usersScene = new ScrollMagic.Scene({
                triggerElement: '.user__list',
                duration: '70%',
                triggerHook: 1,
            })
                .setTween(usersAnimation)
                // .addIndicators(true)
                .addTo(controller)



            let users0 = $('.row__user0');
            let users0Animation = gsap.fromTo(users0, {
                y: 150,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                duration: 1
            });

            let users0Scene = new ScrollMagic.Scene({
                triggerElement: '.row__user0',
                duration: '70%',
                triggerHook: 1,
            })
                .setTween(users0Animation)
                // .addIndicators(true)
                .addTo(controller)




            let users1 = $('.row__user1');
            let users1Animation = gsap.fromTo(users1, {
                y: 150,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                duration: 1
            });

            let users1Scene = new ScrollMagic.Scene({
                triggerElement: '.row__user1',
                duration: '70%',
                triggerHook: 1,
            })
                .setTween(users1Animation)
                // .addIndicators(true)
                .addTo(controller)



            let users2 = $('.row__user2');
            let users2Animation = gsap.fromTo(users2, {
                y: 150,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                duration: 1
            });

            let users2Scene = new ScrollMagic.Scene({
                triggerElement: '.row__user2',
                duration: '70%',
                triggerHook: 1,
            })
                .setTween(users2Animation)
                // .addIndicators(true)
                .addTo(controller)



            let users3 = $('.row__user3');
            let users3Animation = gsap.fromTo(users3, {
                y: 150,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                duration: 1
            });

            let users3Scene = new ScrollMagic.Scene({
                triggerElement: '.row__user3',
                duration: '70%',
                triggerHook: 1,
            })
                .setTween(users3Animation)
                // .addIndicators(true)
                .addTo(controller)


        });
        /* eslint-enable */
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
