// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'; import './autoload/_modernizr.js'

import 'custom-event-polyfill/polyfill';
import '@fancyapps/fancybox/dist/jquery.fancybox';
import 'slick-carousel/slick/slick';
import 'jquery-match-height/jquery.matchHeight';
import 'jquery.nicescroll/dist/jquery.nicescroll.min';
import 'js-cloudimage-360-view/dist/index';
import 'theia-sticky-sidebar/dist/theia-sticky-sidebar.min';

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import banner from './routes/banner';
import home from './routes/home';
import templateOnzeZitvisieData from './routes/templateOnzeZitvisieData';
import templateZoWerktNewtonData from './routes/templateZoWerktNewtonData';
import templateRugleuningenZittingenData from './routes/templateRugleuningenZittingenData';
import templateNewtonInstellenData from './routes/templateNewtonInstellenData';
import templateGebruikersData from './routes/templateGebruikersData';
import templateFaqData from './routes/templateFaqData';
import templateRolstoelframesData from './routes/templateRolstoelframesData';
import templateWetenschappelijkeZitvisieData from './routes/templateWetenschappelijkeZitvisieData';
import templateZitvisieVerticalData from './routes/templateZitvisieVerticalData';
import templateZitgedragData from './routes/templateZitgedragData';
import aboutUs from './routes/about';


/** Populate Router instance with DOM routes */
let routes = {
    // All pages
    common,
    banner,
    home,
    templateOnzeZitvisieData,
    templateZoWerktNewtonData,
    templateRugleuningenZittingenData,
    templateGebruikersData,
    templateFaqData,
    templateRolstoelframesData,
    templateNewtonInstellenData,
    templateWetenschappelijkeZitvisieData,
    templateZitvisieVerticalData,
    templateZitgedragData,
    aboutUs,
};

/* eslint-disable */
import * as modules0 from './modules/templateContact.js'; let modules = [modules0]
if (typeof (modules) !== 'undefined') {
    for (let i = 0; modules.length > i; i++) {
        let module = modules[i];
        if (module.__esModule) {
            let name = Object.keys(module)[0];
            routes[name] = module[name];
        }
    }
}
/* eslint-disable */


let router = new Router(routes);

// Load Events
jQuery(document).ready(() => router.loadEvents());
