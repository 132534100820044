export default {
    init() {
        /* eslint-disable */
        // JavaScript to be fired on the home page
        jQuery(document).ready(function($) {

            let controller = new ScrollMagic.Controller({addIndicators: false});

            let intro = $('.zit__intro__inner');
            let introAnimation = gsap.fromTo(intro, {
                y: 150,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                duration: 1
            });

            let introScene = new ScrollMagic.Scene({
                triggerElement: '.zit__intro__inner',
                duration: '70%',
                triggerHook: 1,
            })
                .setTween(introAnimation)
                // .addIndicators(true)
                .addTo(controller)


            let device = $('.zit__device__content');
            let deviceAnimation = gsap.fromTo(device, {
                y: 150,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                duration: 1
            });

            let deviceScene = new ScrollMagic.Scene({
                triggerElement: '.zit__device__content',
                duration: '70%',
                triggerHook: 1,
            })
                .setTween(deviceAnimation)
                // .addIndicators(true)
                .addTo(controller)


            let deviceImage = $('.zit__device__image');
            let deviceImageAnimation = gsap.fromTo(deviceImage, {
                y: 150,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                duration: 1
            });

            let deviceImageScene = new ScrollMagic.Scene({
                triggerElement: '.zit__device__image',
                duration: '70%',
                triggerHook: 1,
            })
                .setTween(deviceImageAnimation)
                // .addIndicators(true)
                .addTo(controller)

            let benefit = $('.zit__benefit__inner');
            let benefitAnimation = gsap.fromTo(benefit, {
                y: 150,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                duration: 1
            });

            let benefitScene = new ScrollMagic.Scene({
                triggerElement: '.zit__benefit__inner',
                duration: '70%',
                triggerHook: 1,
            })
                .setTween(benefitAnimation)
                // .addIndicators(true)
                .addTo(controller)


            $('.zit__benefit__list li').each(function () {
                let animation = gsap.fromTo($(this), {
                    opacity: 0,
                }, {
                    opacity: 1,
                    duration: 1
                });

                let videoScene = new ScrollMagic.Scene({
                    triggerElement: this,
                    duration: '40%',
                    triggerHook: 0.9,
                })
                    .setTween(animation)
                    // .addIndicators(true)
                    .addTo(controller)
            })




        });
        /* eslint-enable */
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
