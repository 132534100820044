export default {
    init() {
        /* eslint-disable */
        // JavaScript to be fired on the home page


        jQuery(document).ready(function($) {
            $('.dtable__touch').on('click touchstart touch', function (){
                $(this).remove();
            });



           setTimeout(function () {
               $('.dtable__inner table tr').each(function () {
                   let $dtable = $(this).data('tabletr');
                   let $dtableHeight = $(this).outerHeight();

                   $('.dtable__images__item[data-tableimg='+ $dtable +']').height($dtableHeight);

                   $(this).hover(function () {
                       $('.dtable__images__item[data-tableimg='+ $dtable +']').find('.dtable__popup').toggle()
                   })
               })
           }, 500)


            // Default settings
            let controller = new ScrollMagic.Controller({addIndicators: false});

            function blockAnimationImg(index, target) {
                let animation;
                if (index % 2 === 0) {
                    animation = gsap.fromTo(target, {
                        x: 200,
                        opacity: 0,
                    }, {
                        x: 0,
                        opacity: 1,
                        duration: 1
                    });
                } else {
                    animation = gsap.fromTo(target, {
                        x: -200,
                        opacity: 0,
                    }, {
                        x: 0,
                        opacity: 1,
                        duration: 1
                    });
                }

                return animation;
            }

            function blockAnimationText(index, target) {
                let animation;
                if (index % 2 === 0) {
                    animation = gsap.fromTo(target, {
                        x: 150,
                        opacity: 0,
                    }, {
                        x: 0,
                        opacity: 1,
                        duration: 1
                    });
                } else {
                    animation = gsap.fromTo(target, {
                        x: -150,
                        opacity: 0,
                    }, {
                        x: 0,
                        opacity: 1,
                        duration: 1
                    });
                }

                return animation;
            }

            function blockScene(theindex, animation, controller) {
                let result = new ScrollMagic.Scene({
                    triggerElement: '.device__block'+theindex,
                    duration: '40%',
                    triggerHook: 0.9,
                    ease: 'power1.inOut',
                })
                .setTween(animation)
                .addTo(controller)

                return result;
            }

            // Device block foeach animation
            $('.device__block').each(function (index ) {
                let deviceBlockImg = $(this).find('.device__image');
                let deviceBlockAnimationImg = blockAnimationImg(index, deviceBlockImg);
                let deviceBlockSceneImg = blockScene(index, deviceBlockAnimationImg, controller);

                let deviceBlockText = $(this).find('.device__text');
                let deviceBlockAnimationText = blockAnimationText(index, deviceBlockText);
                let deviceBlockSceneText = blockScene(index, deviceBlockAnimationText, controller);
            })


            let tableTr = $('.dtable__content');
            let tableTrAnimation;
            if ( $(window).width() > 0 && $(window).width() < 1199) {
                tableTrAnimation = gsap.fromTo(tableTr, {
                    y: 100
                }, {
                    y: 0
                });
            } else if ($(window).width() >= 1199) {
                tableTrAnimation = gsap.fromTo(tableTr, {
                    y: 190
                }, {
                    y: 0
                });
            }


            let tableTrScene = new ScrollMagic.Scene({
                triggerElement: '.dtable__content',
                duration: '100%',
                triggerHook: 1,
            })
                .setTween(tableTrAnimation)
                // .addIndicators(true)
                .addTo(controller)







        });
        /* eslint-enable */
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
