/* eslint-disable */
// export function vh to px
export function vhTOpx(value) {
    let w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        x = w.innerWidth || e.clientWidth || g.clientWidth,
        y = w.innerHeight|| e.clientHeight|| g.clientHeight;

    let result = (y*value)/100;

    return result;
}

export function magic(trigger, offset, duration, animationVar, controller) {
    let result = new ScrollMagic.Scene({
        triggerElement: trigger,
        offset: vhTOpx(offset),
        duration: duration,
    })
    .setTween(animationVar)
    .addTo(controller)

    return result
}


export function magicMobile(trigger, hook, offset, duration, animationVar, controller) {
    let result = new ScrollMagic.Scene({
        triggerElement: trigger,
        offset: vhTOpx(offset),
        triggerHook: hook,
        duration: duration,
    })
    .setTween(animationVar)
    // .addIndicators(true)
    .addTo(controller)

    return result
}


// export function fadeInRight gsap
export function fadeInRight(target) {
    let animation = gsap.fromTo(target, {
        x: '150px',
        ease: 'none',
        opacity: 0
    }, {
        x: '0px',
        opacity: 1,
        ease: 'none',
        duration: 1
    });

    return animation;
}


export function slideY(target, from, to) {
    let animation = gsap.fromTo(target, {
        y: from,
        ease: "power1.inOut"
    }, {
        y: to,
        duration: 1,
        ease: "power1.inOut"
    });

    return animation;
}


export function slideX(target, from, to) {
    let animation = gsap.fromTo(target, {
        x: from,
        ease: "power1.inOut"
    }, {
        x: to,
        duration: 1,
        ease: "power1.inOut"
    });

    return animation;
}

export function fadeIn(target) {
    let animation = gsap.fromTo(target, {
        ease: 'none',
        opacity: 0
    }, {
        ease: 'none',
        opacity: 1,
        duration: 1
    });

    return animation;
}

export function fadeOut(target) {
    let animation = gsap.fromTo(target, {
        ease: 'none',
        opacity: 1
    }, {
        opacity: 0,
        ease: 'none',
        duration: 1
    });

    return animation;
}

export function leftToRight(target) {
    let animation = gsap.fromTo(target, {
        x: '0%',
    }, {
        x: '100%',
        duration: 1
    });

    return animation;
}

export function bottomToTop(target) {
    let animation = gsap.fromTo(target, {
        y: '0%',
    }, {
        y: '-100%',
        duration: 1
    });

    return animation;
}


export function topToBottom(target) {
    let animation = gsap.fromTo(target, {
        y: '0%',
    }, {
        y: '100%',
        duration: 1
    });

    return animation;
}


export function scrollAnimation(x, scroll) {

    // Gsap horizontal position change smoothscroll.
    gsap.to('.horizontal__inner', {ease: 'power2.inOut', x: x});

    // Fake scrollbar to correct position.
    $('html,body').animate({
        scrollTop: scroll
    }, 1000);
    return false;
}

export function scrollAnimationBack(x, scroll) {

    // Gsap horizontal position change smoothscroll.
    gsap.to('.horizontal__inner', {ease: 'power2.inOut', x: x});

    // Fake scrollbar to correct position.
    $('html,body').animate({
        scrollTop: scroll
    }, 2000);
    return false;
}


/* eslint-enable */
