import {
    fadeIn,
    fadeInRight,
    fadeOut,
    magic,
    magicMobile,
    slideY,
    slideX,
    vhTOpx, scrollAnimationBack,
} from './animationFunctions';

export default {
    init() {
        /* eslint-disable */


        $('.news__slider__slide').slick({
            dots: true,
            infinite: false,
            speed: 500,
            fade: true,
            autoplay: true,
            arrows: false,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
        });

        $('.news__slider__close').on('click', function () {
            $('.news__slider').css('opacity', 0);
        })

        // JavaScript to be fired on the home page
        jQuery(document).ready(function ($) {

            // Detect IE version
            var iev=0;
            var ieold = (/MSIE (\d+\.\d+);/.test(navigator.userAgent));
            var trident = !!navigator.userAgent.match(/Trident\/7.0/);
            var rv=navigator.userAgent.indexOf("rv:11.0");

            if (ieold) {
                iev=new Number(RegExp.$1);
            }
            if (navigator.appVersion.indexOf("MSIE 10") != -1) {
                iev=10;
            }
            if (trident&&rv!=-1) {
                iev=11;
            }

            $('.front-page-data-external .current-menu-item a').click(function (event) {
                event.preventDefault();


                $('.header__menu__link').css('opacity', '0');

                if ( $(window).width() < 1199) {
                    $('.front-page-data-external .section1__bg').css({
                        'height': '70vh'
                    })
                }

                if ($('body').hasClass('is-not-mobile') && iev != 11) {
                    $('body').niceScroll({
                        scrollspeed: 200, // scrolling speed
                        mousescrollstep: 20, // scrolling speed with mouse wheel (pixel)
                        smoothscroll: true,
                        autohidemode: false,
                    });
                }

                $('.header__menu__inner').css({
                    'border-radius' : '0 14px 14px 0',
                    'transform': 'translateX(-445px)',
                })

                $('.header__menu__search').css({
                    'border-radius' : '0 0 8px 0',
                });

                setTimeout(function () {
                    $('.intro').css({
                        'opacity': '1'
                    })

                    $('.header__menu__inner').css({
                        'pointer-events': 'all'
                    })

                    $('.header__menu__btn').css({
                        'opacity': '1',
                    })

                    $('.header__menu__inner').on('mouseenter', function () {
                        if (!$('body').hasClass('header__menu__open')) {
                            $('.header__menu__inner').css('transform', 'translateX(-420px)')
                        }
                    })

                    $('.header__menu__inner').on('mouseleave', function () {
                        if (!$('body').hasClass('header__menu__open')) {
                            $('.header__menu__inner').css('transform', 'translateX(-445px)')
                        }
                    })

                    $('.header__menu__btn').on('click', function () {
                        $('body').toggleClass('header__menu__open');
                        $(this).css({
                            'pointer-events': 'none',
                        });
                        setTimeout(() => {
                            $(this).css({
                                'pointer-events': 'all',
                            })
                        }, 600);
                    })
                }, 1000)



            });

            if ($(window).width() > 1199) {
                // Default settings
                let controller = new ScrollMagic.Controller({addIndicators: false, vertical: true});
                gsap.defaultEase = Linear.easeNone;



                setTimeout(function () {
                    if (window.location.hash == '#homerugleuningen') {
                        if ( $(window).width() > 1199 && $(window).width() < 1799) {
                                scrollAnimationBack('-33.8108%', 1869.5999755859375)
                        } else if ($(window).width() > 1799) {
                                scrollAnimationBack('-33.3363%', 2699)
                        }
                    } else if (window.location.hash == '#homezittingen') {
                        if ( $(window).width() > 1199 && $(window).width() < 1799) {
                                scrollAnimationBack('-50.9257%', 2814.39990234375)
                        } else if ($(window).width() > 1799) {
                                scrollAnimationBack('-49.5798%', 4017)
                        }
                    } else if (window.location.hash == '#homerolstoelframes') {
                        if ( $(window).width() > 1199 && $(window).width() < 1799) {
                                scrollAnimationBack('-66.0344%', 3648.800048828125)
                        } else if ($(window).width() > 1799) {
                                scrollAnimationBack('-63.9003%', 5183)
                        }
                    }
                }, 500)

                // Horizontal animation
                let horizontalAnimation;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    horizontalAnimation = gsap.to('.horizontal__inner', {ease: "none", x: '-83.33334%'});
                } else if ($(window).width() > 1799) {
                    horizontalAnimation = gsap.to('.horizontal__inner', {ease: "none", x: '-79.86%'});
                }

                let pinHorizontal = new ScrollMagic.Scene({
                    triggerElement: '.horizontal',
                    triggerHook: 'onLeave',
                    duration: '600%',
                })
                .setPin('.horizontal')
                .setTween(horizontalAnimation)
                .addTo(controller);



                // Rolstoel animation
                let rolstoel = document.getElementsByClassName('rolstoel__img__full');
                let rolstoelAnimation = fadeOut(rolstoel);
                let rolstoelScene;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    rolstoelScene = magic('.horizontal', 90, '50%', rolstoelAnimation, controller);
                } else if ($(window).width() > 1799) {
                    rolstoelScene = magic('.horizontal', 110, '50%', rolstoelAnimation, controller);
                }

                // Rolstoel tekst
                let rolstoeltext = document.getElementsByClassName('rolstoel__text');
                let rolstoeltextlAnimation = fadeInRight(rolstoeltext);
                let rolstoeltextScene;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    rolstoeltextScene = magic('.horizontal', 125, '40%', rolstoeltextlAnimation, controller);
                } else if ($(window).width() > 1799) {
                    rolstoeltextScene = magic('.horizontal', 150, '40%', rolstoeltextlAnimation, controller);
                }

                let rolstoeltextTitle = $('.rolstoel__text h2');
                let rolstoeltextTitleAnimation = fadeInRight(rolstoeltextTitle);
                let rolstoeltextTitleScene;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    let rolstoeltextTitleScene = magic('.horizontal', 135, '30%', rolstoeltextTitleAnimation, controller);
                } else if ($(window).width() > 1799) {
                    let rolstoeltextTitleScene = magic('.horizontal', 160, '30%', rolstoeltextTitleAnimation, controller);
                }

                // Rotate animation
                let $frame_count = 36;
                let $offsetVal;
                let $offset;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    $offset = vhTOpx(230);
                    $offsetVal = 13;
                } else if ($(window).width() > 1799) {
                    $offset = vhTOpx(230);
                    $offsetVal = 23;
                }

                for (var i = 1, l = $frame_count; i <= l; i++) {
                    new ScrollMagic.Scene({
                        triggerElement: '.horizontal',
                        offset: $offset,
                    })
                    .setClassToggle('.rotate__images', 'state'+i)
                    .addTo(controller);

                    $offset = $offset + $offsetVal;
                }

                let rotateText = document.getElementsByClassName('rotate__text');
                let rotateTextAnimation = fadeInRight(rotateText);
                let rotateTextScene;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    rotateTextScene = magic('.horizontal', 250, '40%', rotateTextAnimation, controller);
                } else if ($(window).width() > 1799) {
                    rotateTextScene = magic('.horizontal', 260, '40%', rotateTextAnimation, controller);
                }

                let rotateTextTitle = $('.rotate__text h3');
                let rotateTextTitleAnimation = fadeInRight(rotateTextTitle);
                let rotateTextTitleScene;
                if ( $(window).width() > 1199 && $(window).width() < 1799) {
                    rotateTextTitleScene = magic('.horizontal', 260, '30%', rotateTextTitleAnimation, controller);
                } else if ($(window).width() > 1799) {
                    rotateTextTitleScene = magic('.horizontal', 270, '30%', rotateTextTitleAnimation, controller);
                }


                let newtonIdImg = document.getElementsByClassName('zitting__image1');
                let newtonIdImgAnimation = fadeIn(newtonIdImg);
                let newtonIdImgScene = magic('.horizontal', 350, '25%', newtonIdImgAnimation, controller);

                let zitting1 = document.getElementsByClassName('zitting__image1');
                let zitting1Animation = slideY(zitting1, '0', '-145px');
                let zitting1Scene = magic('.horizontal', 375, '55%', zitting1Animation, controller);

                let zitting2 = document.getElementsByClassName('zitting__image2');
                let zitting2Animation = slideY(zitting2, '0', '145px');
                let zitting2Scene = magic('.horizontal', 375, '55%', zitting2Animation, controller);

                let zittingText = document.getElementsByClassName('zitting__text');
                let zittingTextAnimation = fadeInRight(zittingText);
                let zittingTextScene = magic('.horizontal', 370, '40%', zittingTextAnimation, controller);

                let zittingTextTitle = $('.zitting__text h3');
                let zittingTextTitleAnimation = fadeInRight(zittingTextTitle);
                let zittingTextTitleScene = magic('.horizontal', 380, '30%', zittingTextTitleAnimation, controller);


                let flexibel = document.getElementsByClassName('flexibel__slider');
                let flexibelAnimation = slideY(flexibel, '-350px', '600px');
                let flexibelScene = magic('.horizontal', 435, '80%', flexibelAnimation, controller);

                let flexibelText = document.getElementsByClassName('flexibel__text');
                let flexibelTextAnimation = fadeInRight(flexibelText);
                let flexibelTextScene = magic('.horizontal', 480, '40%', flexibelTextAnimation, controller);

                let flexibelTextTitle = $('.flexibel__text h3');
                let flexibelTextTitleAnimation = fadeInRight(flexibelTextTitle);
                let flexibelTextTitleScene = magic('.horizontal', 490, '30%', flexibelTextTitleAnimation, controller);


                // Footer: Animation
                let next = document.getElementsByClassName('next');
                let nextAnimation = fadeInRight(next);
                let nextScene = magic('.horizontal', 540, '40%', nextAnimation, controller);

                let nextTitle = document.getElementsByClassName('next__go');
                let nextTitleAnimation = fadeInRight(nextTitle);
                let nextTitleScene = magic('.horizontal', 550, '30%', nextTitleAnimation, controller);
            } else {
                $('.zitting__images img:first-child').load(function () {
                    let $getHeight = $(this).height();
                    $('.zitting__images').css({
                        'min-height' : $getHeight,
                    })
                });


                // Default settings
                let controller = new ScrollMagic.Controller({addIndicators: false, vertical: true});
                gsap.defaultEase = Linear.easeNone;

                let rolstoelImage = document.getElementsByClassName('rolstoel__img__full');
                let rolstoelImageAnimation = fadeOut(rolstoelImage);
                let rolstoelImageScene = magicMobile('.rolstoel__img', 0.9, 0, '30%', rolstoelImageAnimation, controller);

                let rolstoelText = document.getElementsByClassName('rolstoel__text');
                let rolstoelTextAnimation = fadeIn(rolstoelText);
                let rolstoelTextScene = magicMobile('.rolstoel__text', 0.9, 0, '30%', rolstoelTextAnimation, controller);


                // Rotate animation
                let $frame_count = 35;
                let $offset = 0;
                let $offsetVal
                if ( $(window).width() > 768 && $(window).width() <= 1199) {
                    $offsetVal = 15;
                } else if ($(window).width() <= 768) {
                    $offsetVal = 12;
                }

                for (var i = 1, l = $frame_count; i <= l; i++) {
                    new ScrollMagic.Scene({
                        triggerElement: '.section3',
                        offset: $offset,
                    })
                        .setClassToggle('.rotate__images', 'state'+i)
                        .addTo(controller);

                    $offset = $offset + $offsetVal;
                }


                let rotateImage = document.getElementsByClassName('rotate');
                let rotateImageAnimation = fadeIn(rotateImage);
                let rotateImageScene = magicMobile('.rotate',  0.9, 0, '30%', rotateImageAnimation, controller);

                let rotateText = document.getElementsByClassName('rotate__text');
                let rotateTextAnimation = fadeIn(rotateText);
                let rotateTextScene = magicMobile('.rotate__text',  0.9, 0, '30%', rotateTextAnimation, controller);

                let zittingImage = document.getElementsByClassName('zitting');
                let zittingImageAnimation = fadeIn(zittingImage);
                let zittingImageScene = magicMobile('.zitting',  0.9, 0, '30%', zittingImageAnimation, controller);

                let newtonIdImg = document.getElementsByClassName('zitting__image1');
                let newtonIdImgAnimation = fadeIn(newtonIdImg);
                let newtonIdImgScene = magicMobile('.section4', 0.55 , 0, '15%', newtonIdImgAnimation, controller);

                let zitting1 = document.getElementsByClassName('zitting__image1');
                let zitting1Animation = slideY(zitting1, '0', '-55px');
                let zitting1Scene = magicMobile('.section4', 0.7 , 0, '40%', zitting1Animation, controller);

                let zitting2 = document.getElementsByClassName('zitting__image2');
                let zitting2Animation = slideY(zitting2, '0', '55px');
                let zitting2Scene = magicMobile('.section4', 0.7 , 0, '40%', zitting2Animation, controller);

                let zittingText = document.getElementsByClassName('zitting__text');
                let zittingTextAnimation = fadeIn(zittingText);
                let zittingTextScene = magicMobile('.zitting__text', 0.9 , 0, '20%', zittingTextAnimation, controller);

                let flexibelFade = document.getElementsByClassName('flexibel');
                let flexibelFadeAnimation = fadeIn(flexibelFade);
                let flexibelFadeScene = magicMobile('.flexibel',  0.9, 0, '30%', flexibelFadeAnimation, controller);

                let flexibel = document.getElementsByClassName('flexibel__slider');
                let flexibelAnimation;
                if ( $(window).width() > 767 && $(window).width() <= 1199) {
                    flexibelAnimation = slideX(flexibel, '0', '-40%');
                } else if ($(window).width() <= 767) {
                    flexibelAnimation = slideX(flexibel, '0', '-100%');
                }
                let flexibelScene = magicMobile('.section5', 0.55 , 0, '50%', flexibelAnimation, controller);

                let flexibelText = document.getElementsByClassName('flexibel__text');
                let flexibelTextAnimation = fadeIn(flexibelText);
                let flexibelTextScene = magicMobile('.flexibel__text', 0.9 , 0, '30%', flexibelTextAnimation, controller);

                let last = $('.next');
                let lastAnimation = fadeIn(last);
                let lastImageScene = magicMobile('.section__last', 0.9, 0, '30%', lastAnimation, controller);
            }


        });
        /* eslint-enable */
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
